<template>
  <div>
    <transition name="fade-three" mode="out-in">
      <router-view />
    </transition>
    <van-tabbar v-model="active" ruote z-index='1000' safe-area-inset-bottom>
      <van-tabbar-item replace :to="item.path" :name="item.id" v-for="item in tabBarArr" class="tab-item"
        :class="{ bigItem: item.routeName == $route.name }" :key="item.id">
        <template #icon="props">
          <img :src="props.active ? item.selected : item.normal" />
        </template>
        {{ item.name }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "tabbar",
  data() {
    return {
      show: true,
      tabBarArr: [
        {
          id: 0, //ID号
          name: "首页", //名称
          path: "/",
          routeName: "/", //路由名称
          normal: require("@/assets/img/tab3.png"), //未选中时图标
          selected: require("@/assets/img/tab1.png"), //选中时图标
        },
        {
          id: 1,
          name: "个人",
          path: "/mine",
          routeName: "mine",
          normal: require("@/assets/img/tab2.png"),
          selected: require("@/assets/img/tab4.png"),
        }
      ],
      isShowBottom: true, //显示或者隐藏footer
      documentHeight: document.documentElement.clientHeight, //默认屏幕高度
    }
  },
  computed: {
    active: {
      get: function () {
        let obj = this.tabBarArr.find(val => val.routeName == this.$route.name)
        return obj.id || 0
      },
      set: function (data) { },
    }
  },
  methods: {},
  mounted() {
    window.onresize = () => {
      return (() => {
        if (this.documentHeight > document.documentElement.clientHeight) {
          this.isShowBottom = false
        } else {
          this.isShowBottom = true
        }
      })()
    }
  },
}
</script>
<style lang="less" scoped>
.fade-three-enter-active,
.fade-three-leave-active {
  transition: opacity 0.15s;
}
.fade-three-enter,
.fade-three-leave-to
/* .fade-three-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
/deep/.van-tabbar {
  .van-tabbar-item {
    background-color: #000000;

    .van-tabbar-item__text {
      color: #B1B1B1;
    }
  }
  .van-tabbar-item--active {
    .van-tabbar-item__text {
      color: #FFFFFF;
    }
  }
}
</style>
